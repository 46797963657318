<template>
  <div class="h-full">
    <div
      class="fixed top-0 z-40 flex mt-0 flex-col shadow-lg transition-width transition-slowest ease-linear bg-white"
      :class="{
        'w-full sm:w-[280px]': isSidebarOpen && isShowSidebar,
        'w-0 p-0 hidden': !isSidebarOpen || !isShowSidebar,
      }"
    >
      <div
        class="h-[100vh] w-[280px] flex flex-col border-r border-gray-200 pt-[180px] lg:pt-[80px] bg-white transition-all transition-slowest ease-linear box-border overflow-y-auto"
      >
        <div v-if="horseTrackingCard && isShowSidebar">
          <HorseInfoComponent
            v-if="showHorseInfo"
            :horseTrackingCard="horseTrackingCard"
          />
          <TrainingsCalendarComponent
            v-if="!isComparison"
            @changeTraining="changeTraining"
          />
          <ComparisonCalendar v-else />
          <ChartsSelectorComponent
            v-if="isShowChartSelector"
            :availableChartsList="availableChartsList"
          />
        </div>
      </div>
    </div>
    <main
      class="max-w-full min-h-[calc(100vh_-_60px)] flex flex-grow transition-all transition-slowest ease-linear p-0 m-0 z-0"
      :class="{ 'pl-[280px] hidden sm:block': isSidebarOpen && isShowSidebar }"
    >
      <div
        class="w-full bg-[#F2F2F8] h-full flex justify-center items-center p-4"
      >
        <div
          class="w-full min-h-[calc(100vh_-_170px)] mt-[120px] md:mt-[65px] rounded-md flex flex-col justify-start items-center"
          :class="{ 'bg-white p-4': routeName === routeNames.taskboard }"
        >
          <slot></slot>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ChartsSelectorComponent from "@/components/blocks/sidebar/ChartsSelectorComponent.vue";
import TrainingsCalendarComponent from "@/components/blocks/sidebar/TrainingsCalendarComponent.vue";
import HorseInfoComponent from "@/components/training/HorseInfoComponent.vue";
import routeNames from "@/router/routes";
import { trackingModeKeys } from "@/components/training/constants";
import ComparisonCalendar from "@/components/training/comparison/ComparisonCalendar.vue";

export default {
  name: "SidebarComponent",
  props: {
    isSidebarOpen: Boolean,
  },
  components: {
    HorseInfoComponent,
    ChartsSelectorComponent,
    TrainingsCalendarComponent,
    ComparisonCalendar,
  },
  data() {
    return {
      routeNames,
      trackingModeKeys,
      availableChartsList: [
        {
          isEnabled: false,
          title: "charts-selector.Stiffness power",
          data: "Stiffness power",
          type: "training",
        },
        {
          isEnabled: false,
          title: "charts-selector.Activity",
          data: "Activity",
          type: "training",
        },
        {
          isEnabled: true,
          title: "tracking.Multichart",
          data: "Multichart",
          type: "tracking",
        },
        {
          isEnabled: true,
          title: "tracking.Cumulative energy",
          data: "Cumulative energy",
          type: "tracking",
        },
        {
          isEnabled: true,
          title: "tracking.Expenditure energy",
          data: "Expenditure energy",
          type: "tracking",
        },
        {
          isEnabled: true,
          title: "tracking.Center of gravity",
          data: "Center of gravity",
          type: "tracking",
        },
        {
          isEnabled: true,
          title: "comparison.Multichart",
          data: "Comparison Multichart",
          type: "comparison",
        },
        {
          isEnabled: true,
          title: "comparison.Skewness and hist history",
          data: "Skewness and hist history",
          type: "comparison",
        },
        {
          isEnabled: true,
          title: "comparison.Cumulative energy costs history",
          data: "Cumulative energy costs history",
          type: "comparison",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "horseTrackingCard",
      "selectedChartsList",
      "selectedDayTrainings",
      "reportsCompareTrainingFrom",
      "reports",
      "selectedTrainingTimeData",
      "selectedDayTrainingsMetadata",
      "sharingInList",
      "initialPositionGPS",
    ]),
    routeName() {
      return this.$route.name;
    },
    showHorseInfo() {
      return (
        (this.$route.name === routeNames.tracking &&
          this.horseTrackingCard &&
          this.reports) ||
        this.$route.name === routeNames.comparison
      );
    },
    isComparison() {
      return this.$route.name === routeNames.comparison;
    },
    isShowSidebar() {
      return (
        this.$route.name === routeNames.training ||
        this.$route.name === routeNames.centerOfGravityTracking ||
        this.$route.name === routeNames.tracking ||
        this.$route.name === routeNames.comparison
      );
    },
    isShowCalendar() {
      return this.$route.name !== routeNames.comparison
        ? this.selectedDayTrainings.length
        : true;
    },
    isShowChartSelector() {
      return !!(
        this.$route.name === routeNames.training ||
        this.$route.name === routeNames.comparison ||
        (this.$route.name === routeNames.tracking &&
          this.selectedDayTrainings.length)
      );
    },
  },
  methods: {
    ...mapMutations([
      "SET_LOADING",
      "SET_TRACKING_MODE",
      "SET_GOOGLE_MAPS_CENTER",
      "SET_MULTICHART_LOCAL_ZOOM",
      "SET_GOOGLE_MAPS_ZOOM",
      "SET_GOOGLE_MAPS_HEADING",
    ]),
    ...mapActions([
      "getTrainingReports",
      "getCurrentTrainingData",
      "getHorseLameness",
      "getGPSDatafileList",
      "getGPSDatafile",
      "getHRDatafileList",
      "getHRDatafile",
    ]),

    async changeTraining(data) {
      try {
        this.SET_LOADING(true);

        await Promise.all([
          this.getTrainingReports({ reports: data.results, id: data.id }),
          data.id !== +this.$route.params.trainingId &&
            this.getCurrentTrainingData(data.id),
          this.getHorseLameness(this.$route.params.id),
          this.getGPSDatafileList(data.id).then(async (res) => {
            if (res.length) {
              const selectedTrackingMode =
                localStorage.getItem("tracking-mode");
              this.SET_TRACKING_MODE(
                selectedTrackingMode
                  ? selectedTrackingMode
                  : this.trackingModeKeys.GPS
              );
              await this.getGPSDatafile(data.id);
              if (this.initialPositionGPS) {
                this.SET_GOOGLE_MAPS_CENTER({
                  lat: this.initialPositionGPS.latitude,
                  lng: this.initialPositionGPS.longitude,
                });
              }
            } else {
              this.SET_TRACKING_MODE(this.trackingModeKeys.ML);
            }
          }),
          this.getHRDatafileList(data.id).then(async (res) => {
            if (res.length) {
              await this.getHRDatafile(data.id);
            }
          }),
        ]);

        this.SET_MULTICHART_LOCAL_ZOOM({
          x: { min: 0, max: 0 },
        });
        this.SET_GOOGLE_MAPS_ZOOM(17);
        this.SET_GOOGLE_MAPS_HEADING(90);

        this.SET_LOADING(false);
      } catch (error) {
        console.error("Error changing training:", error);
        this.SET_LOADING(false);
      }
    },
  },
};
</script>
