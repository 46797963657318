<template>
  <div class="w-full flex flex-wrap gap-4 mb-4">
    <div
      v-for="chart in legendWithData.value"
      :key="chart.scaleId"
      class="flex items-center"
    >
      <div class="h-3 w-3 mr-2" :style="{ backgroundColor: chart.color }"></div>
      <div class="text-xs">{{ chart.title }}: {{ chart.currentValue }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LegendComponent",
  inject: ["legendWithData"],
};
</script>
