<template>
  <div
    v-if="!isHorseDontHaveTrainings && reports"
    class="w-full self-start font-monrope"
  >
    <div
      class="max-w-[1655px] w-full mx-auto flex flex-wrap flex-col xl:flex-row items-center xl:items-start justify-center"
      :class="setHealthClass"
    >
      <div class="z-1">
        <HorseInfoComponent
          v-if="horseTrackingCard && reports?.steps_map"
          class="mx-0 mt-0 mb-2 self-start min-w-[335px] w-full"
          :horseTrackingCard="horseTrackingCard"
        />
        <HorseHealthComponent
          :lamenessData="reports.LAMENESS_REPORT"
          :reports="reports"
        />
        <div class="flex">
          <Button
            class="md:mr-1 flex justify-center"
            @click="goToPrevInfoPage"
            :disabled="pageForInfo === 0"
          >
            <ArrowLongLeftIcon class="h-5 w-5 text-center" aria-hidden="true" />
          </Button>
          <Button
            class="md:ml-1 flex justify-center"
            @click="goToNextInfoPage"
            :disabled="pageForInfo === 1"
          >
            <ArrowLongRightIcon
              class="h-5 w-5 text-center"
              aria-hidden="true"
            />
          </Button>
        </div>
      </div>

      <div>
        <div v-show="pageForInfo === 0">
          <div class="flex w-full ml-2">
            <div class="w-full max-w-[280px]">
              <SelectControl
                :style="{
                  flexDirection: 'row',
                  marginBottom: '8px',
                  width: '100%',
                }"
                dataKey="trackingMode"
                :data="trackingMode"
                :options="trackingModesOptions"
                @update="handleTrackingMode"
              />
              <TableComponent
                v-if="Object.keys(reports).length"
                class="mt-0"
                :tableData="tableData"
              />
            </div>

            <DoughnutsInfoComponent />
          </div>

          <div class="tabs w-full mt-2 ml-2">
            <div class="block">
              <nav
                class="isolate flex flex-col md:flex-row flex-wrap divide-x divide-gray-200 rounded-lg shadow"
                aria-label="Tabs"
              >
                <button
                  v-for="(tab, tabIdx) in tabs"
                  :key="tab.name"
                  :class="[
                    tabIdx === 0 ? 'rounded-l-lg' : '',
                    tab.isEnabled ? '' : 'hidden',
                    tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                    'group relative w-[300px] flex-1 overflow-hidden bg-white py-4 px-4 text-center hover:bg-gray-50 focus:z-10 text-[#5A5A5F] text-[18px] font-semibold',
                  ]"
                  :aria-current="tab.current ? 'page' : undefined"
                  @click="handleSwitchTab(tabIdx)"
                >
                  <span>{{ $t(tab.name) }}</span>
                  <span
                    aria-hidden="true"
                    :class="[
                      tab.current ? 'bg-[#9A8053]' : 'bg-indigo-200',
                      'absolute inset-x-0 bottom-0 h-0.5',
                    ]"
                  />
                </button>
              </nav>
            </div>

            <div v-show="currentTabIdx === 0">
              <ExpenditureEnergyComponent
                v-if="reports && reports.EXPENDITURE_ENERGY_METRICS"
                :expenditureEnergy="
                  reports.EXPENDITURE_ENERGY_METRICS.raw
                    ? reports.EXPENDITURE_ENERGY_METRICS.raw
                    : reports.EXPENDITURE_ENERGY_METRICS
                "
              />
            </div>

            <div v-show="currentTabIdx === 1">
              <StiffnessPowerComponent
                v-if="
                  selectedChartsList.includes('Stiffness power') &&
                  reports.STIFFNESS_POWER_DISTRIBUTION
                "
                :datasets="reports.STIFFNESS_POWER_DISTRIBUTION"
                :title="$t('charts-selector.Stiffness power')"
              />
            </div>

            <div v-show="currentTabIdx === 2">
              <BarChartComponent
                v-if="
                  selectedChartsList.includes('Activity') &&
                  reports.STIFFNESS_METRICS.raw
                    ? reports.STIFFNESS_METRICS.raw
                    : reports.STIFFNESS_METRICS
                "
                :title="$t('charts-selector.Activity')"
                :description="selectedDayTrainingsMetadata.STIFFNESS_METRICS"
                type="table"
                :date="currentDate"
                :datasets="
                  reports.STIFFNESS_METRICS.raw
                    ? reports.STIFFNESS_METRICS.raw
                    : reports.STIFFNESS_METRICS
                "
              />
            </div>
          </div>
        </div>

        <div v-if="pageForInfo === 1">
          <div class="w-full ml-2">
            <SignaturesComponent
              v-if="horseLameness"
              :title="$t('charts-selector.Lameness history')"
              :chartsData="horseLameness"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="isHorseDontHaveTrainings"
    class="h-[calc(100vh_-_150px)] w-full flex justify-center items-center text-[#5A5A5F] text-[18px] font-semibold text-center"
  >
    {{ $t("tracking.No training data for this horse") }}
  </div>
</template>

<script>
import { format } from "date-fns";

import { mapActions, mapGetters, mapMutations } from "vuex";
import HorseHealthComponent from "@/components/training/index/HorseHealthComponent";
import DoughnutsInfoComponent from "@/components/training/index/DoughnutsInfoComponent";
import StiffnessPowerComponent from "@/components/training/index/StiffnessPowerComponent";
import SignaturesComponent from "@/components/training/index/SignaturesComponent";
import BarChartComponent from "@/components/training/index/charts/BarChartComponent.vue";
import HorseInfoComponent from "@/components/training/HorseInfoComponent.vue";
import ExpenditureEnergyComponent from "@/components/training/index/ExpenditureEnergyComponent.vue";
import { enUS, ru } from "date-fns/locale";
import TableComponent from "@/components/UI/TableComponent.vue";
import Button from "@/components/UI/Button.vue";
import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/vue/20/solid";
import { convertSecondsToTs } from "@/components/training/helpers";
import {
  trackingModes,
  trackingModeKeys,
  allures,
} from "@/components/training/constants";
import SelectControl from "@/components/UI/SelectControl.vue";

export default {
  name: "ChartsComponent",
  components: {
    SelectControl,
    Button,
    TableComponent,
    ExpenditureEnergyComponent,
    HorseInfoComponent,
    BarChartComponent,
    StiffnessPowerComponent,
    SignaturesComponent,
    HorseHealthComponent,
    DoughnutsInfoComponent,
    ArrowLongLeftIcon,
    ArrowLongRightIcon,
  },
  data() {
    return {
      trackingModes,
      allures,
      currentTabIdx: 0,
      index: 0,
      trainingData: {
        training: null,
      },
      pageForInfo: 0,
    };
  },
  computed: {
    ...mapGetters([
      "chartsData",
      "horsesList",
      "selectedChartsList",
      "horseLameness",
      "reports",
      "selectedDayTrainingsMetadata",
      "currentTrainingMetadata",
      "horseTrackingCard",
      "isHorseDontHaveTrainings",
      "GPSDatafiles",
      "HRDatafiles",
      "trackingMode",
    ]),
    trackingModesOptions() {
      return this.GPSDatafiles?.length
        ? this.trackingModes
        : [trackingModeKeys.ML];
    },
    tabs() {
      return [
        {
          name: "tracking.Expenditure energy",
          href: "#",
          current: this.currentTabIdx === 0,
          isEnabled: true,
        },
        {
          name: "charts-selector.Stiffness power",
          href: "#",
          current: this.currentTabIdx === 1,
          isEnabled: this.selectedChartsList.includes("Stiffness power"),
        },
        {
          name: "charts-selector.Activity",
          href: "#",
          current: this.currentTabIdx === 2,
          isEnabled: this.selectedChartsList.includes("Activity"),
        },
      ];
    },
    setHealthClass() {
      if (this.reports.steps_map?.length || this.reports.STIFFNESS_METRICS) {
        return "xl:flex-nowrap";
      } else {
        return "xl:flex-wrap";
      }
    },
    horse() {
      return this.horsesList.find(
        (h) => h.id === Number(this.$route.params.id)
      );
    },
    currentDate() {
      const locale = this.$i18n.locale === "Ru" ? ru : enUS;
      const date = this.selectedDayTrainingsMetadata.date
        ? new Date(this.selectedDayTrainingsMetadata.date)
        : new Date();
      return format(date, "dd MMM yyyy", { locale: locale });
    },
    trainingDuration() {
      if (!this.reports.training_duration_seconds) return "";
      if (this.trackingMode === trackingModeKeys.GPS) {
        return this.convertSecondsToTs(
          this.reports.total_training_duration_GPS
        );
      } else if (this.trackingMode === trackingModeKeys.ML) {
        return this.convertSecondsToTs(this.reports.total_training_duration_ML);
      }
      return "00:00:00";
    },
    allEnergyCost() {
      return this.reports.total_energy_expended
        ? (this.reports.total_energy_expended / 1000).toFixed(2)
        : 0;
    },
    totalDistance() {
      if (this.GPSDatafiles && this.reports.total_distance_gps_meters) {
        if (this.trackingMode === trackingModeKeys.GPS) {
          return Math.ceil(this.reports.total_distance_gps_meters);
        } else if (this.trackingMode === trackingModeKeys.ML) {
          return Math.ceil(this.reports.total_distance_ml_meters);
        }
      } else if (this.reports.TRACKING && this.reports.TRACKING.ts) {
        return this.reports.total_distance_ml_meters;
      }
      return "-";
    },
    maxSpeed() {
      if (this.GPSDatafiles && this.reports.total_max_speed_GPS) {
        if (this.trackingMode === trackingModeKeys.GPS) {
          return this.reports.total_max_speed_GPS.toFixed(2);
        } else if (this.trackingMode === trackingModeKeys.ML) {
          return this.reports.total_max_speed_ML.toFixed(2);
        }
      } else if (this.reports.total_max_speed_ML) {
        return this.reports.total_max_speed_ML.toFixed(2);
      }
      return "-";
    },
    avgSpeed() {
      if (this.reports.TRACKING && this.reports.TRACKING.ts) {
        const [minutes, seconds] = this.trainingDuration.split(":").map(Number);
        const timeInSeconds = minutes * 60 + seconds;

        const avgSpeed = this.totalDistance / timeInSeconds;
        return Math.round(avgSpeed * 100) / 100;
      }
      return "-";
    },
    maxAcceleration() {
      if (this.reports && this.reports.total_max_acceleration_ML) {
        return this.reports.total_max_acceleration_ML.toFixed(2);
      }
      return "-";
    },
    avgTouchStiffness() {
      if (this.reports && this.reports.total_average_stiffness_power) {
        return this.reports.total_average_stiffness_power.toFixed(2);
      }
      return "-";
    },
    avgHR() {
      if (this.reports && this.HRDatafiles?.length && this.reports.hr_average) {
        return Math.round(this.reports.hr_average);
      }
      return "-";
    },
    maxHR() {
      if (this.reports && this.HRDatafiles?.length && this.reports.hr_max) {
        return Math.round(this.reports.hr_max);
      }
      return "-";
    },
    rhythmDeviation() {
      if (!this.reports.total_average_rhythm_deviation) return "-";
      return this.reports.total_average_rhythm_deviation.toFixed(2);
    },
    jumpCount() {
      if (!this.reports.JUMP_count) return "-";
      return this.reports.JUMP_count;
    },
    tableData() {
      return [
        {
          name: this.$t("training.Training total distance"),
          value: this.totalDistance,
          disabled: false,
        },
        {
          name: this.$t("training.Training duration"),
          value: this.trainingDuration,
          disabled: false,
        },
        {
          name: this.$t("training.Total energy expended"),
          value: this.allEnergyCost,
          disabled: false,
        },
        {
          name: this.$t("training.Average speed"),
          value: this.avgSpeed,
          disabled: false,
        },
        {
          name: this.$t("training.Max speed"),
          value: this.maxSpeed,
          disabled: false,
        },
        {
          name: this.$t("training.Max acceleration"),
          value: this.maxAcceleration,
          disabled: false,
        },
        {
          name: this.$t("training.Average touch stiffness"),
          value: this.avgTouchStiffness,
          disabled: false,
        },
        {
          name: this.$t("training.Average HR"),
          value: this.avgHR,
          disabled: Boolean(!this.HRDatafiles),
        },
        {
          name: this.$t("training.Max HR"),
          value: this.maxHR,
          disabled: Boolean(!this.HRDatafiles),
        },
        {
          name: this.$t("training.Rhythm deviation"),
          value: this.rhythmDeviation,
          disabled: false,
        },
        {
          name: this.$t("training.Jump count"),
          value: this.jumpCount,
          disabled: false,
        },
      ];
    },
  },
  watch: {
    selectedCharts(newData) {
      this.availableChartsList.map((i) => {
        newData.includes(i.data) ? (i.isEnabled = true) : (i.isEnabled = false);
      });
    },
  },
  methods: {
    ...mapMutations([
      "SET_REPORTS_DATA",
      "SET_GPS_DATAFILES",
      "SET_HR_DATAFILES",
      "SET_HORSE_LAMENESS",
      "SET_CURRENT_DAY_TRAININGS_METADATA",
    ]),
    ...mapActions(["handleTrackingMode"]),
    convertSecondsToTs,

    goToNextInfoPage() {
      this.pageForInfo = this.pageForInfo + 1;
    },
    goToPrevInfoPage() {
      this.pageForInfo = this.pageForInfo - 1;
    },
    handleSwitchTab(index) {
      this.tabs.map((i) => (i.current = false));
      this.tabs[index].current = true;
      this.currentTabIdx = index;
    },
  },
  beforeUnmount() {
    this.SET_REPORTS_DATA([]);
    this.SET_GPS_DATAFILES(null);
    this.SET_HR_DATAFILES(null);
    this.SET_HORSE_LAMENESS(null);
  },
};
</script>

<style lang="scss" scoped>
:deep(#trackingMode) {
  height: 40px;
  font-size: 14px;
}
</style>
