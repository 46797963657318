function getClass(val) {
  return `bg-[${val}]`;
}

function getSecond(secondTs) {
  const timeParts = secondTs.split(":").map(Number);
  const seconds = timeParts[0] * 3600 + timeParts[1] * 60 + timeParts[2]; // Calculate total seconds
  return Math.floor(seconds); // Always round down to the nearest whole number
}

function convertSecondsToTs(seconds) {
  const roundedSeconds = Math.round(seconds);
  const minutes = Math.floor(roundedSeconds / 60)
    .toString()
    .padStart(2, "0");
  const remainingSeconds = (roundedSeconds % 60).toString().padStart(2, "0");
  return `${minutes}:${remainingSeconds}`;
}

function formatTimestamp(timestamp) {
  const parts = timestamp.split(":");

  // Extract hours, minutes, and seconds
  const hours = parts[0];
  const minutes = parts[1];
  const seconds = parts[2].split(".")[0]; // Remove milliseconds

  // Format the output based on whether hours are present or not
  if (hours !== "00") {
    return `${parseInt(hours)}:${minutes}:${seconds}`;
  } else {
    return `${minutes}:${seconds}`;
  }
}

export { getClass, getSecond, formatTimestamp, convertSecondsToTs };
