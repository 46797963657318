<template>
  <ModalComponent
    small
    :is-open="isModalOpen"
    :confirmHidden="!editMode"
    @handleCancel="handleCancel"
    @handleAccept="handleAccept"
  >
    <template v-slot:title>
      <div class="text-center">
        {{
          horseInfo ? $t("add-horse.Horse details") : $t("add-horse.New horse")
        }}
      </div>
    </template>
    <template v-slot:content>
      <div class="absolute right-3 top-3 hidden pr-4 pt-4 sm:block">
        <button
          type="button"
          class="rounded-md bg-white text-gray-400 focus:outline-none"
          @click="handleCancel"
        >
          <span class="sr-only">Close</span>
          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div v-if="update" class="flex justify-end items-center">
        <span class="mr-4 text-xs text-[#5A5A5F] font-semibold">{{
          $t("add-horse.Edit mode")
        }}</span>
        <Switch
          v-model="editMode"
          class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
        >
          <span class="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            class="pointer-events-none absolute h-full w-full rounded-md bg-white"
          />
          <span
            aria-hidden="true"
            :class="[
              editMode ? 'bg-[#9A8053]' : 'bg-gray-200',
              'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
            ]"
          />
          <span
            aria-hidden="true"
            :class="[
              editMode ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
            ]"
          />
        </Switch>
      </div>
      <div v-if="editMode" class="text-[#5A5A5F] text-[32px] font-medium">
        <button
          v-if="horseInfo"
          class="flex ml-auto text-xs mt-8 p-2 rounded bg-red-600 text-white"
          @click="deleteHorseConfirmModal = true"
        >
          {{ $t("add-horse.Delete horse") }}
        </button>
        <InputControl
          disabled
          type="text"
          :label="$t('add-horse.Horse name')"
          :data="horseData?.name"
          dataKey="name"
          :error="!!v$.horseData.name.$errors"
          :errorText="v$.horseData.name.$errors[0]?.$message"
          @update="onFieldChange"
        />
        <InputControl
          type="text"
          :label="$t('add-horse.Horse age')"
          :data="horseData?.age"
          dataKey="age"
          :error="!!v$.horseData.age.$errors"
          :errorText="v$.horseData.age.$errors[0]?.$message"
          @update="onFieldChange"
        />
        <SelectControl
          :label="$t('add-horse.Horse gender')"
          dataKey="gender"
          :data="horseData?.gender"
          :options="['MALE', 'FEMALE']"
          @update="onFieldChange"
        />
        <InputControl
          type="text"
          :label="$t('add-horse.Horse weight')"
          :data="horseData?.weight"
          dataKey="weight"
          :error="!!v$.horseData.weight.$errors"
          :errorText="v$.horseData.weight.$errors[0]?.$message"
          @update="onFieldChange"
        />
        <InputControl
          type="text"
          :label="$t('add-horse.Horse RFid')"
          :data="horseData?.rfId"
          dataKey="rfId"
          :error="!!v$.horseData.rfId.$errors"
          :errorText="v$.horseData.rfId.$errors[0]?.$message"
          @update="onFieldChange"
        />
        <!--        <FileControl-->
        <!--          :label="$t('add-horse.Horse photo')"-->
        <!--          dataKey="avatarUrl"-->
        <!--          @update="onFieldChange"-->
        <!--        />-->
        <div class="flex justify-start">
          <CheckboxControl
            dataKey="favorite"
            id="modalAccept"
            :value="horseData.favorite"
            @update="onFieldChange"
          >
            <span class="mb-2 text-sm cursor-pointer">
              {{ $t("add-horse.Favourites") }}
            </span>
          </CheckboxControl>
        </div>
      </div>
      <div v-else class="w-full my-2 flex flex-col justify-start items-center">
        <img
          :src="setAvatarUrl(horseInfo.avatarUrl)"
          class="rounded-full shadow-xl w-[180px] h-[180px] mx-auto mb-2 object-cover"
        />
        <div
          class="text-[#5A5A5F] text-[18px] font-medium text-center mb-2 mt-4"
        >
          {{ $t("add-horse.Horse info") }}
        </div>
        <div
          class="w-full h-[56px] bg-gray-100 rounded my-1 p-4 flex justify-start items-center text-[#5A5A5F] font-semibold text-md shadow"
        >
          <span>{{ $t("add-horse.Horse name") }}:</span>
          <span class="ml-2">{{ horseInfo.name }}</span>
        </div>
        <div
          class="w-full h-[56px] bg-gray-100 rounded my-1 p-4 flex justify-start items-center text-[#5A5A5F] font-semibold text-md shadow"
        >
          <span>{{ $t("add-horse.Horse age") }}:</span>
          <span class="ml-2"
            >{{ horseInfo.age }} {{ horseInfo.age < 2 ? "year" : "years" }}
          </span>
        </div>
        <div
          class="w-full h-[56px] bg-gray-100 rounded my-1 p-4 flex justify-start items-center text-[#5A5A5F] font-semibold text-md shadow"
        >
          <span>{{ $t("add-horse.Horse gender") }}:</span>
          <span class="ml-2">{{ horseInfo.gender }}</span>
        </div>
        <div
          class="w-full h-[56px] bg-gray-100 rounded my-1 p-4 flex justify-start items-center text-[#5A5A5F] font-semibold text-md shadow"
        >
          <span>{{ $t("add-horse.Horse weight") }}:</span>
          <span class="ml-2">{{ horseInfo.weight }} kg</span>
        </div>
        <div
          class="w-full h-[56px] bg-gray-100 rounded my-1 p-4 flex justify-start items-center text-[#5A5A5F] font-semibold text-md shadow"
        >
          <span>{{ $t("add-horse.Horse RFid") }}:</span>
          <span class="ml-2">{{ horseInfo.rfId }}</span>
        </div>
      </div>
      <div class="text-[#5A5A5F] text-[18px] font-medium text-center mb-2">
        {{ $t("add-horse.Disciplines") }}
      </div>
      <div class="flex flex-wrap items-start justify-between mb-4">
        <div
          v-if="!editMode"
          class="flex flex-wrap justify-around items-center"
        >
          <div
            v-for="(item, index) of disciplines"
            :key="index"
            class="py-2 px-4 my-2 mx-1 bg-my-page rounded-full text-center text-[#676767]"
            :class="{
              'bg-[#9A8053]': horseInfo.disciplines.includes(item),
              'bg-gray-300': !horseInfo.disciplines.includes(item),
              ' cursor-pointer': editMode,
            }"
            @click="handleEditDiscipline(item)"
          >
            <p class="text-[16px]" :class="{ 'text-white': item }">
              {{ item }}
            </p>
          </div>
        </div>
        <div v-else class="flex flex-wrap justify-around items-center">
          <div
            v-for="(item, index) of disciplines"
            :key="index"
            class="py-2 px-4 my-2 mx-1 bg-my-page rounded-full text-center text-[#676767]"
            :class="{
              'bg-[#9A8053]': horseData.disciplines.includes(item),
              'bg-gray-300': !horseData.disciplines.includes(item),
              ' cursor-pointer': editMode,
            }"
            @click="handleEditDiscipline(item)"
          >
            <p class="text-[16px]" :class="{ 'text-white': item }">
              {{ item }}
            </p>
          </div>
        </div>
        <div
          v-if="!editMode && horseSharingOutList.length"
          class="w-full mt-6 mb-2 flex flex-col justify-start items-center"
        >
          <div class="text-[#5A5A5F] text-[18px] font-medium text-center mb-2">
            {{ $t("share.Sharing list") }}
          </div>
          <div class="w-full">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("share.User email") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                  >
                    {{ $t("share.Status") }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr v-for="request in horseSharingOutList" :key="request.id">
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ request.user.login }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-right text-gray-500"
                    :class="{
                      'text-green-500': request.status === 'APPROVED',
                      'text-red-500': request.status === 'DECLINED',
                    }"
                  >
                    {{ request.status }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </ModalComponent>
  <ModalComponent
    small
    :is-open="deleteHorseConfirmModal"
    @handleCancel="handleCloseModal('delete')"
    @handleAccept="confirmDeleteEvent()"
  >
    <template v-slot:title>
      <span class="px-2">{{ $t("add-horse.Сonfirm event deletion") }}</span>
    </template>
    <template v-slot:content>
      <span class="text-[#5A5A5F] font-semibold px-2">
        {{ $t("add-horse.Are you sure that you want to remove") }}
        <span class="font-bold">{{ this.horseInfo.name }}</span>
        {{ $t("add-horse.horse from list") }}?
      </span>
    </template>
  </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/ModalComponent";
import InputControl from "@/components/UI/InputControl";
import SelectControl from "@/components/UI/SelectControl";
import { Switch } from "@headlessui/vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, numeric, required } from "@vuelidate/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import CheckboxControl from "@/components/UI/CheckboxControl";
import { XMarkIcon } from "@heroicons/vue/24/outline";
export default {
  name: "AddHorseComponent",
  components: {
    XMarkIcon,
    ModalComponent,
    InputControl,
    SelectControl,
    Switch,
    CheckboxControl,
  },
  props: {
    isModalOpen: Boolean,
    horseInfo: Object,
    update: Boolean,
  },
  data() {
    return {
      v$: useVuelidate(),
      deleteHorseConfirmModal: false,
      editMode: true,
      disciplines: [
        "Vaulting",
        "Triathlon",
        "Western",
        "Jumping",
        "Driving",
        "Dressage",
        "Racing",
      ],
      horseData: {
        name: "",
        age: "",
        gender: "",
        weight: "",
        favorite: false,
        avatarUrl: "",
        disciplines: [],
        rfId: "",
      },
      shareData: {
        email: "",
      },
    };
  },
  validations: {
    horseData: {
      name: {
        required: helpers.withMessage("This field is required", required),
      },
      age: {
        required: helpers.withMessage("This field is required", required),
        numeric: helpers.withMessage("This field must be a numeric", numeric),
      },
      weight: {
        required: helpers.withMessage("This field is required", required),
        numeric: helpers.withMessage("This field must be a numeric", numeric),
      },
      rfId: {
        required: helpers.withMessage("This field is required", required),
        numeric: helpers.withMessage("This field must be a numeric", numeric),
      },
    },
  },
  mounted() {
    if (this.update) {
      this.editMode = false;
      if (this.horseInfo)
        this.horseData = JSON.parse(JSON.stringify(this.horseInfo));
    }
  },
  computed: {
    ...mapGetters(["sharingOutList"]),
    horseSharingOutList() {
      return this.sharingOutList.length
        ? this.sharingOutList.filter((i) => i.horse.id === this.horseInfo.id)
        : [];
    },
  },
  methods: {
    ...mapActions([
      "getHorsesList",
      "updateHorse",
      "addHorse",
      "deleteHorse",
      "shareHorse",
      "getSharingOutList",
    ]),
    ...mapMutations(["SET_LOADING"]),
    setAvatarUrl(url) {
      if (url && url.includes("https://")) return url;
      else return require("@/assets/horse.png");
    },
    handleEditDiscipline(item) {
      if (this.editMode) {
        this.horseData?.disciplines.includes(item)
          ? (this.horseData.disciplines = this.horseData.disciplines.filter(
              (i) => i !== item
            ))
          : this.horseData.disciplines.push(item);
      }
    },
    onFieldChange(val) {
      this.horseData[val.key] = val.data;
    },
    shareEmailChange(val) {
      this.shareData.email = val.data;
    },
    handleCancel() {
      if (this.update && this.editMode) this.editMode = false;
      else {
        this.clearFields();
        this.$emit("closeModal");
      }
    },
    async handleAccept() {
      if (this.editMode) {
        this.v$.$validate();
        if (!this.v$.$error) {
          this.SET_LOADING(true);
          if (this.update) {
            const data = {
              id: this.horseData.id,
              horseData: {
                name: this.horseData.name,
                avatarUrl: this.horseData.avatarUrl,
                age: this.horseData.age,
                favorite: this.horseData.favorite,
                gender: this.horseData.gender,
                rfId: this.horseData.rfId,
                weight: this.horseData.weight,
                disciplines: this.horseData.disciplines,
              },
            };
            await this.updateHorse(data);
            this.$emit("closeModal");
            this.clearFields();
          } else {
            delete this.horseData.avatarUrl;
            await this.addHorse(this.horseData);
            this.$emit("closeModal");
            this.clearFields();
          }
          await this.getHorsesList(this.$route.params.page);

          this.SET_LOADING(false);
        }
      } else {
        this.clearFields();
        this.$emit("closeModal");
      }
    },
    handleCloseModal() {
      this.deleteHorseConfirmModal = false;
    },
    async confirmDeleteEvent() {
      this.SET_LOADING(true);
      await this.deleteHorse(this.horseData.id);
      await this.getHorsesList(this.$route.params.page);
      this.SET_LOADING(false);
    },
    clearFields() {
      this.horseData = {
        name: "",
        age: "",
        gender: "",
        weight: "",
        favorite: false,
        avatarUrl: "",
        disciplines: [],
        rfId: "",
      };
    },
  },
};
</script>
