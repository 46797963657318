const state = {
  isFullscreenMultichartOpen: false,
  localZoom: {
    x: { min: 0, max: 0 },
  },
};

const getters = {
  isFullscreenMultichartOpen: (state) => state.isFullscreenMultichartOpen,
  localZoom: (state) => state.localZoom,
};

const mutations = {
  SET_IS_FULLSCREEN_MULTICHART_OPEN(state, data) {
    state.isFullscreenMultichartOpen = data;
  },
  SET_MULTICHART_LOCAL_ZOOM(state, data) {
    state.localZoom = data;
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
