import { trackingModeKeys } from "@/components/training/constants";
import api from "@/axiosConfig";
import { useToast } from "vue-toastification";

const toast = useToast();

const state = {
  speed: 1,
  currentTrackIndex: 0,
  trackingMarkersLength: 0,
  isTrackStarted: false,
  isTrackPaused: true,
  trackingMode: trackingModeKeys.GPS,
  GPSDatafilesList: [],
  GPSDatafiles: null,
  HRDatafilesList: [],
  HRDatafiles: null,
  initialPositionGPS: { latitude: 0, longitude: 0 },
  isAnimationModalOpen: false,
};

const getters = {
  speed: (state) => state.speed,
  currentTrackIndex: (state) => state.currentTrackIndex,
  isTrackStarted: (state) => state.isTrackStarted,
  isTrackPaused: (state) => state.isTrackPaused,
  trackingMarkersLength: (state) => state.trackingMarkersLength,
  trackingMode: (state) => state.trackingMode,
  GPSDatafilesList: (state) => state.GPSDatafilesList,
  GPSDatafiles: (state) => state.GPSDatafiles,
  HRDatafilesList: (state) => state.HRDatafilesList,
  HRDatafiles: (state) => state.HRDatafiles,
  initialPositionGPS: (state) => state.initialPositionGPS,
  isAnimationModalOpen: (state) => state.isAnimationModalOpen,
};

const mutations = {
  SET_TRACKING_SPEED(state, data) {
    state.speed = data;
  },
  SET_CURRENT_TRACK_INDEX(state, data) {
    state.currentTrackIndex = data;
  },
  SET_IS_TRACK_STARTED(state, data) {
    state.isTrackStarted = data;
  },
  SET_IS_TRACK_PAUSED(state, data) {
    state.isTrackPaused = data;
  },
  SET_TRACKING_MARKERS_LENGTH(state, data) {
    state.trackingMarkersLength = data;
  },
  SET_TRACKING_MODE(state, data) {
    state.trackingMode = data;
  },
  SET_GPS_DATAFILES(state, value) {
    if (value && value.data) {
      state.GPSDatafiles = Array.isArray(value.data)
        ? value.data
        : value.data.report;
      state.initialPositionGPS = value.data.initialPosition;

      if (!state.GPSDatafiles.length) {
        state.trackingMode = trackingModeKeys.ML;
      }
    }
  },
  SET_GPS_DATAFILES_LIST(state, value) {
    state.GPSDatafilesList = value.data.datafiles;
    const datafileTypes = value.data.datafiles.map((item) => item.type);
    if (!datafileTypes.includes("GPS_TRACKING")) {
      state.GPSDatafilesList = null;
      state.HRDatafiles = null;
    }
  },
  SET_HR_DATAFILES(state, value) {
    if (value && value.data) {
      state.HRDatafiles = Array.isArray(value.data)
        ? value.data
        : value.data.report;
    } else {
      state.HRDatafiles = null;
    }
  },
  SET_HR_DATAFILES_LIST(state, value) {
    state.HRDatafilesList = value.data.datafiles;
    const datafileTypes = value.data.datafiles.map((item) => item.type);
    if (!datafileTypes.includes("HEART_RATE")) {
      state.HRDatafilesList = null;
    }
  },
  SET_IS_ANIMATION_MODAL_OPEN(state, data) {
    state.isAnimationModalOpen = data;
  },
};

const actions = {
  async getGPSDatafileList({ commit }, params) {
    return api
      .get(`datafile/training/${params}/list`, {
        params: { type: "GPS_TRACKING" },
      })
      .then((res) => {
        commit("SET_GPS_DATAFILES_LIST", res);
        return res.data.datafiles;
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },
  async getHRDatafileList({ commit }, params) {
    return api
      .get(`datafile/training/${params}/list`, {
        params: { type: "HEART_RATE" },
      })
      .then((res) => {
        commit("SET_HR_DATAFILES_LIST", res);
        return res.data.datafiles;
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },
  async getGPSDatafile({ commit }, id) {
    return api
      .get(`datafile/training/${id}/file`, { params: { type: "GPS_TRACKING" } })
      .then((res) => {
        commit("SET_GPS_DATAFILES", res);
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },
  async getHRDatafile({ commit }, id) {
    return api
      .get(`datafile/training/${id}/file`, { params: { type: "HEART_RATE" } })
      .then((res) => {
        if (res?.response?.status === 500) {
          toast.error("HR datafile request failed with status code 500");
        } else {
          commit("SET_HR_DATAFILES", res);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },
  playTrack({ commit, state }) {
    commit("SET_IS_TRACK_STARTED", true);
    commit("SET_IS_TRACK_PAUSED", false);

    const interval = setInterval(() => {
      if (!state.isTrackPaused) {
        if (state.trackingMarkersLength > state.currentTrackIndex) {
          commit(
            "SET_CURRENT_TRACK_INDEX",
            state.currentTrackIndex + state.speed
          );
        } else {
          commit("SET_IS_TRACK_STARTED", false);
          commit("SET_IS_TRACK_PAUSED", true);
          clearInterval(interval);
        }
      }
    }, 200);
  },
  pauseTrack({ commit }) {
    commit("SET_IS_TRACK_STARTED", false);
    commit("SET_IS_TRACK_PAUSED", true);
    clearInterval(this.interval);
  },
  handleTrackingMode({ commit }, value) {
    commit("SET_TRACKING_MODE", value.data);
    localStorage.setItem("tracking-mode", value.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
