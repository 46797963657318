<template>
  <div class="mx-auto w-[100%] lg:max-w-[42%] min-h-[350px] overflow-hidden">
    <div v-show="isTrackingDataExist">
      <div class="flex items-center">
        <button
          v-show="trackingMode === trackingModeKeys.GPS"
          class="text-[12px] w-auto rounded p-2 bg-[#9A8053] text-white mb-2 mr-4"
          @click="handleClick"
        >
          Open comparison modal
        </button>
        <div class="mb-2 mr-4">
          <input
            type="checkbox"
            class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
            id="isTrackColored"
            v-model="isTrackColored"
          />
          <label
            for="isTrackColored"
            class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2"
          >
            <span> {{ $t("tracking.Show gaits") }} </span>
          </label>
        </div>
        <div class="mb-2 mr-2" v-show="trackingMode === trackingModeKeys.GPS">
          <input
            type="checkbox"
            class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
            id="isMapVisible"
            v-model="isMapVisible"
          />
          <label
            for="isMapVisible"
            class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2"
          >
            <span> {{ $t("tracking.Show maps") }} </span>
          </label>
        </div>
      </div>

      <div class="relative">
        <div v-show="isMapVisible" class="relative h-[450px]">
          <GoogleMaps
            v-if="!isFullscreenMapActive && !isMultimodalMapActive"
            mapId="miniMap"
            :tracking="tracking"
            :selectedGaits="selectedGaits"
            :trackingMarkersLength="trackingMarkersLength"
            :isTrackColored="isTrackColored"
          />
        </div>

        <div
          id="tracking-chart"
          v-show="!isMapVisible"
          class="absolute top-0 left-0 bottom-0 right-0 z-1"
        ></div>
      </div>
    </div>

    <GoogleMapsModal
      v-if="isFullscreenMapActive"
      :is-open="isFullscreenMapActive"
      xMark
      large
      @handleCancel="handleCancel"
    >
      <template v-slot:title>
        <div class="flex items-center gap-3 mb-3">Google Maps Track</div>
      </template>
      <template v-slot:content>
        <div class="w-full flex flex-wrap gap-4 mb-4 h-[80%] relative">
          <GoogleMaps
            mapId="fullscreenMap"
            :tracking="tracking"
            :selectedGaits="selectedGaits"
            :trackingMarkersLength="trackingMarkersLength"
            :isTrackColored="isTrackColored"
          />
        </div>

        <div class="mt-6 mb-4 mx-8 flex items-center gap-8">
          <div class="w-[30%]">
            <PlayControllerButtonsComponent
              :trackingMarkersLength="trackingMarkersLength"
              :cutValue="cutValue"
            />
            <div class="flex mt-1 gap-2">
              <div
                v-for="(item, index) of movements"
                :key="index"
                class="flex items-center mx-1"
              >
                <input
                  type="checkbox"
                  class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
                  :id="item.title"
                  v-model="item.checked"
                />
                <span
                  class="w-[10px] h-[10px] mx-1 mb-[3px] rounded-full"
                  :class="getClass(item.backgroundColor)"
                ></span>
                <span class="text-[#5A5A5F] text-[18px]">
                  {{ $t(item.title) }}
                </span>
              </div>
            </div>
          </div>

          <div class="w-[70%]">
            <PlayControllerSliderComponent
              :trackingMarkersLength="trackingMarkersLength"
              :cutValue="cutValue"
            />
          </div>
        </div>
      </template>
    </GoogleMapsModal>

    <div v-show="!isTrackingDataExist">{{ $t("Data not found") }}</div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist";
import { mapGetters, mapMutations } from "vuex";
import GoogleMapsModal from "@/components/training/tracking/GoogleMapsModal.vue";
import PlayControllerSliderComponent from "@/components/training/tracking/PlayControllerSliderComponent.vue";
import PlayControllerButtonsComponent from "@/components/training/tracking/PlayControllerButtonsComponent.vue";
import { movements, trackingModeKeys } from "@/components/training/constants";
import { getClass } from "@/components/training/helpers";
import GoogleMaps from "@/components/training/tracking/GoogleMaps.vue";

export default {
  name: "HorseTrackingComponent",
  components: {
    GoogleMaps,
    PlayControllerButtonsComponent,
    PlayControllerSliderComponent,
    GoogleMapsModal,
  },
  props: {
    tracking: Array,
    fromToCutValue: Array,
    lengthPreviousValues: Number,
    selectedGaits: Array,
    trackingMarkersLength: Number,
    cutValue: Array,
  },
  data() {
    return {
      movements,
      trackingModeKeys,

      arr: [],
      mappedData: [],
      gaitTrackingMarkers: [],
      fromToCutMappedValue: [],
      trackingPoints: {},
      isTrackColored: true,
      isMapVisible: false,
      selectedGaitColors: [],
      marker: null,
      currentPositionMarker: null,
      isTrackingMarkersLengthChanged: false,
      trackPlanCoordinates: [],
    };
  },
  watch: {
    currentTrackIndex() {
      if (!this.isAnimationModalOpen) {
        Plotly.react("tracking-chart", this.data, this.layout, {
          displayModeBar: false,
          scrollZoom: true,
        });
      }
    },

    async tracking() {
      if (!this.isAnimationModalOpen) {
        if (this.trackingMode !== this.trackingModeKeys.GPS) {
          this.isMapVisible = false;
        }

        this.setTrackingPoints();
        await Plotly.react("tracking-chart", this.data, this.layout, {
          displayModeBar: false,
          scrollZoom: true,
        });
        this.resetZoom();
      }
    },

    fromToCutValue() {
      this.fromToCutMappedValue = this.fromToCutValue;
      Plotly.react("tracking-chart", this.data, this.layout, {
        displayModeBar: false,
        scrollZoom: true,
      });
    },

    historyArray() {
      if (!this.isAnimationModalOpen) {
        Plotly.react("tracking-chart", this.data, this.layout, {
          displayModeBar: false,
          scrollZoom: true,
        });
      }
    },

    selectedGaits: {
      handler: async function (newVal) {
        this.selectedGaitColors = newVal
          .filter((i) => i.checked)
          .map((i) => i.backgroundColor);
        Plotly.react("tracking-chart", this.data, this.layout, {
          displayModeBar: false,
          scrollZoom: true,
        });
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      "currentTrackIndex",
      "GPSDatafiles",
      "reports",
      "trackingMode",
      "isAnimationModalOpen",
      "isMultimodalMapActive",
      "isFullscreenMapActive",
    ]),
    isTrackingDataExist() {
      return this.tracking && this.tracking.length > 0;
    },
    layout() {
      return { ...this.getDefaultLayout() };
    },
    data() {
      if (!this.isTrackingDataExist) return null;
      const maxValue = this.tracking.length - 1;
      let marker = {
        x: [
          this.tracking[
            this.currentTrackIndex > this.tracking.length - 1
              ? maxValue
              : this.currentTrackIndex
          ].x,
        ],
        y: [
          this.tracking[
            this.currentTrackIndex > this.tracking.length - 1
              ? maxValue
              : this.currentTrackIndex
          ].y,
        ],
        mode: "markers",
        name: "Scatter",
        marker: {
          color: "red",
          size: 8,
        },
      };
      if (this.fromToCutMappedValue.length) {
        let sliceFromMarkerId = this.tracking.find(
          (i) => i.id === this.fromToCutMappedValue[0]
        );
        let sliceToMarkerId = this.tracking.find(
          (i) => i.id === this.fromToCutMappedValue[1]
        );

        let sliceFromMarkerIndex = this.tracking.findIndex(
          (i) => i.id === this.fromToCutMappedValue[0]
        );
        let sliceToMarkerIndex = this.tracking.findIndex(
          (i) => i.id === this.fromToCutMappedValue[1]
        );

        let SliceFromMarker = {
          x: [sliceFromMarkerId ? sliceFromMarkerId.x : this.tracking[0].x],
          y: [sliceFromMarkerId ? sliceFromMarkerId.y : this.tracking[0].y],
          mode: "markers",
          name: "Scatter",
          marker: {
            color: "grey",
            size: 4,
          },
        };
        let SliceToMarker = {
          x: [
            sliceToMarkerId
              ? sliceToMarkerId.x
              : this.tracking[this.tracking.length - 1].x,
          ],
          y: [
            sliceToMarkerId
              ? sliceToMarkerId.y
              : this.tracking[this.tracking.length - 1].y,
          ],
          mode: "markers",
          name: "Scatter",
          marker: {
            color: "grey",
            size: 4,
          },
        };

        let sliceFromTrack = {
          x: this.trackingPoints.x.slice(
            0,
            sliceFromMarkerIndex > -1 ? sliceFromMarkerIndex : 0
          ),
          y: this.trackingPoints.y.slice(
            0,
            sliceFromMarkerIndex > -1 ? sliceFromMarkerIndex : 0
          ),
          mode: "lines",
          line: {
            dash: "dash",
            color: "#d2d1d1",
            width: 0.5,
          },
        };
        let sliceToTrack = {
          x: this.trackingPoints.x.slice(
            sliceToMarkerIndex > -1 ? sliceToMarkerIndex : 0,
            sliceToMarkerIndex > -1 ? this.trackingPoints.x.length - 1 : 0
          ),
          y: this.trackingPoints.y.slice(
            sliceToMarkerIndex > -1 ? sliceToMarkerIndex : 0,
            sliceToMarkerIndex > -1 ? this.trackingPoints.y.length - 1 : 0
          ),
          mode: "lines",
          line: {
            dash: "dash",
            color: "#d2d1d1",
            width: 0.5,
          },
        };

        let track = {
          x: this.trackingPoints.x.slice(
            sliceFromMarkerIndex > -1 ? sliceFromMarkerIndex : 0,
            sliceToMarkerIndex > -1
              ? sliceToMarkerIndex
              : this.trackingPoints.x.length - 1
          ),
          y: this.trackingPoints.y.slice(
            sliceFromMarkerIndex > -1 ? sliceFromMarkerIndex : 0,
            sliceToMarkerIndex > -1
              ? sliceToMarkerIndex
              : this.trackingPoints.y.length - 1
          ),
          mode: "lines",
          line: {
            dash: "dash",
            color: "#5b5959",
            width: 0.5,
          },
        };
        return [
          ...this.previousValues,
          SliceFromMarker,
          SliceToMarker,
          marker,
          sliceFromTrack,
          sliceToTrack,
          track,
        ];
      } else {
        let track = {
          x: this.trackingPoints.x,
          y: this.trackingPoints.y,
          mode: "lines",
          line: {
            dash: "dash",
            color: "#5b5959",
            width: 0.5,
          },
        };
        return [...this.previousValues, track, marker];
      }
    },

    historyArray() {
      if (!this.isTrackColored) {
        const val =
          this.currentTrackIndex > this.lengthPreviousValues
            ? this.lengthPreviousValues
            : this.currentTrackIndex;
        return this.tracking.slice(
          this.currentTrackIndex - val,
          this.currentTrackIndex
        );
      }
      return this.tracking;
    },

    previousValues() {
      let type = [];
      let res = [];

      this.historyArray.map((i, index) => {
        if (
          this.historyArray[index + 1] &&
          i.color === this.historyArray[index + 1].color
        ) {
          type.push(i);
        } else if (
          !this.historyArray[index + 1] ||
          i.color !== this.historyArray[index + 1].color
        ) {
          type.push(i);
          if (this.selectedGaitColors.includes(type[0].color)) {
            res.push({
              mode: "lines",
              x: type.map((i) => i.x),
              y: type.map((i) => i.y),
              line: {
                color: this.selectedGaitColors.includes(type[0].color)
                  ? type[0].color
                  : "silver",
                width: 0.8,
              },
            });
          }

          type = [];
        }
      });
      return res;
    },
  },
  methods: {
    ...mapMutations([
      "SET_IS_FULLSCREEN_MAP_ACTIVE",
      "SET_IS_MULTIMODAL_MAP_ACTIVE",
    ]),

    getClass,
    handleClick() {
      this.SET_IS_MULTIMODAL_MAP_ACTIVE(true);
    },
    handleCancel() {
      this.SET_IS_FULLSCREEN_MAP_ACTIVE(false);
    },
    getDefaultLayout() {
      return {
        dragmode: "pan",
        autosize: true,
        showlegend: false,
        hovermode: false,
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        margin: {
          l: 50,
          r: 10,
          b: 40,
          t: 10,
        },
        xaxis: {
          title: {
            text: this.$t("tracking.X Axis in meters"),
            font: {
              family: "sans-serif, Manrope",
              size: 14,
              color: "lightgrey",
            },
          },
          showgrid: true,
          zeroline: false,
          showline: false,
          showticklabels: true,
          autorange: true,
          nticks: 10,
          domain: [0, 1],
        },
        yaxis: {
          title: {
            text: this.$t("tracking.Y Axis in meters"),
            font: {
              family: "sans-serif, Manrope",
              size: 14,
              color: "lightgrey",
            },
          },
          showgrid: true,
          zeroline: false,
          showline: false,
          showticklabels: true,
          autorange: true,
          scaleanchor: "x",
          nticks: 10,
          domain: [0, 1],
        },
      };
    },

    resetZoom() {
      const defaultLayout = this.getDefaultLayout();
      Plotly.relayout("tracking-chart", defaultLayout).then(() => {
        const plotDiv = document.getElementById("tracking-chart");
        const xAxisRange = plotDiv.layout.xaxis.range;
        const yAxisRange = plotDiv.layout.yaxis.range;

        // Calculate new ranges based on the desired zoom out level (0.85x)
        const zoomOutFactor = 0.15; // 15% zoom out
        const newXAxisRange = [
          xAxisRange[0] - ((xAxisRange[1] - xAxisRange[0]) * zoomOutFactor) / 2,
          xAxisRange[1] + ((xAxisRange[1] - xAxisRange[0]) * zoomOutFactor) / 2,
        ];
        const newYAxisRange = [
          yAxisRange[0] - ((yAxisRange[1] - yAxisRange[0]) * zoomOutFactor) / 2,
          yAxisRange[1] + ((yAxisRange[1] - yAxisRange[0]) * zoomOutFactor) / 2,
        ];

        Plotly.relayout("tracking-chart", {
          "xaxis.range": newXAxisRange,
          "yaxis.range": newYAxisRange,
        });
      });
    },

    setTrackingPoints() {
      this.trackingPoints.y = this.tracking.map((i) => i.y);
      this.trackingPoints.x = this.tracking.map((i) => i.x);
    },
  },

  mounted() {
    if (this.isTrackingDataExist) {
      this.selectedGaitColors = this.selectedGaits
        .filter((i) => i.checked)
        .map((i) => i.backgroundColor);

      this.setTrackingPoints();
      Plotly.newPlot("tracking-chart", this.data, this.layout, {
        displayModeBar: false,
        scrollZoom: true,
      });
      this.resetZoom();
    }
  },
};
</script>

<style lang="scss" scoped>
.controls {
  display: flex;

  button {
    padding: 5px 10px;
    margin: 0 5px;
    background: #fff;
    color: #666;
  }
}
</style>
