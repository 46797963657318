<template>
  <ModalComponent
    large
    confirmHidden
    xMark
    :is-open="isMultimodalMapActive"
    @handleCancel="handleCancel"
  >
    <template v-slot:title>
      <div class="ml-auto flex items-center gap-3 mb-3">
        <div
          v-for="item of multicharts"
          :key="item.scaleID"
          class="flex items-center ml-4 mb-4"
        >
          <input
            type="checkbox"
            class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
            :style="{ color: item.color }"
            :id="item.title"
            v-model="item.checked"
          />
          <label
            :for="item.title"
            class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2 font-bold border-gray-700"
          >
            <span>{{ $t(item.title) }}</span>
          </label>
        </div>
      </div>

      <LegendComponent />
    </template>
    <template v-slot:content>
      <div class="relative w-full h-[500px] mb-4">
        <GoogleMaps
          v-if="!isFullscreenMapActive"
          mapId="multiModalMap"
          :tracking="tracking"
          :selectedGaits="selectedGaits"
          :trackingMarkersLength="trackingMarkersLength"
        />
      </div>

      <div class="relative w-full h-[450px]">
        <MultichartWrapper
          :mappedData="mappedData"
          :multicharts="multicharts"
          :selectedGaits="selectedGaits"
          :cutValue="cutValue"
          :secondsByGait="secondsByGait"
          :trackingMarkersLength="trackingMarkersLength"
        />
      </div>

      <div class="mb-4 mt-2 mx-8 flex items-center gap-8">
        <div class="w-[30%]">
          <PlayControllerButtonsComponent
            :trackingMarkersLength="trackingMarkersLength"
            :cutValue="cutValue"
          />
          <div class="flex flex-wrap mt-1">
            <div
              v-for="item of movements"
              :key="item.id"
              class="flex items-center mx-1"
            >
              <input
                type="checkbox"
                class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
                :style="{ color: item.backgroundColor }"
                :id="item.title"
                v-model="item.checked"
              />
              <label
                :for="item.title"
                class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2 font-bold border-gray-700"
              >
                <span>{{ $t(item.title) }}</span>
              </label>
            </div>
          </div>
        </div>

        <div class="w-[70%]">
          <PlayControllerSliderComponent
            :trackingMarkersLength="trackingMarkersLength"
            :cutValue="cutValue"
          />
        </div>
      </div>
    </template>
  </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/ModalComponent.vue";
import GoogleMaps from "@/components/training/tracking/GoogleMaps.vue";
import { mapGetters, mapMutations } from "vuex";
import MultichartWrapper from "@/components/training/tracking/trackingComponents/multiChart/MultichartWrapper.vue";
import PlayControllerButtonsComponent from "@/components/training/tracking/PlayControllerButtonsComponent.vue";
import PlayControllerSliderComponent from "@/components/training/tracking/PlayControllerSliderComponent.vue";
import LegendComponent from "@/components/training/tracking/trackingComponents/multiChart/LegendComponent.vue";

export default {
  name: "MultiModal",
  components: {
    LegendComponent,
    PlayControllerSliderComponent,
    PlayControllerButtonsComponent,
    MultichartWrapper,
    GoogleMaps,
    ModalComponent,
  },
  props: {
    tracking: Array,
    selectedGaits: Array,
    trackingMarkersLength: Number,
    mappedData: Array,
    multicharts: Array,
    cutValue: Array,
    secondsByGait: Array,
    movements: Array,
  },

  computed: {
    ...mapGetters([
      "isFullscreenMultichartOpen",
      "isFullscreenMapActive",
      "isMultimodalMapActive",
    ]),
  },

  methods: {
    ...mapMutations(["SET_IS_MULTIMODAL_MAP_ACTIVE"]),
    handleCancel() {
      this.SET_IS_MULTIMODAL_MAP_ACTIVE(false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
