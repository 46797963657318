<template>
  <div class="grid grid-cols-2 w-full items-start ml-2 gap-2 auto-rows-max">
    <DoughnutChartComponent
      v-if="reports.steps_map && reports.steps_map.walk"
      :title="$t('training.Training distance')"
      :type="$t('training.Meters')"
      :data="distanceByGait"
      :disabled="!distanceByGait"
      :comparedData="totalDistance"
    />

    <DoughnutChartComponent
      v-if="reports.STIFFNESS_METRICS"
      :title="$t('training.Energy expended')"
      :type="$t('training.kJ')"
      :data="energyCoast"
      :disabled="!energyCoast"
      :comparedData="allEnergyCost"
    />

    <DoughnutChartComponent
      v-if="reports.steps_map && reports.steps_map.walk"
      :class="!reports.total_sum_stiffness_power && 'col-span-full'"
      :title="$t('training.Training duration')"
      :type="$t('training.min')"
      :data="timeCoastByWalkingType"
      :comparedData="trainingDuration"
    />

    <DoughnutChartComponent
      v-if="reports.total_sum_stiffness_power"
      :title="$t('training.Power expended')"
      :type="$t('training.kWatts')"
      :data="powerCoast"
      :comparedData="allPowerCost"
    />

    <DoughnutChartComponent
      v-if="this.reports.total_average_rhythm_deviation"
      class="col-span-full"
      :title="$t('training.Rhythm deviation')"
      :data="rhythmDeviationByAllure"
      :comparedData="rhythmDeviation"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import DoughnutChartComponent from "@/components/training/index/charts/DoughnutChartComponent.vue";
import { convertSecondsToTs } from "@/components/training/helpers";
import { trackingModeKeys, allures } from "@/components/training/constants";

export default {
  name: "DoughnutsInfoComponent",
  components: {
    DoughnutChartComponent,
  },
  data() {
    return {
      allures,
    };
  },
  computed: {
    ...mapGetters(["reports", "GPSDatafiles", "HRDatafiles", "trackingMode"]),
    trainingDuration() {
      if (!this.reports.training_duration_seconds) return "";
      if (this.trackingMode === trackingModeKeys.GPS) {
        return this.convertSecondsToTs(
          this.reports.total_training_duration_GPS
        );
      } else if (this.trackingMode === trackingModeKeys.ML) {
        return this.convertSecondsToTs(this.reports.total_training_duration_ML);
      }
      return "00:00:00";
    },
    allPowerCost() {
      return Math.round(this.reports.total_sum_stiffness_power / 1000);
    },
    powerCoast() {
      if (!this.reports.total_sum_stiffness_power) return [];
      const powerData = {
        walk: +this.reports.by_allures_sum_stiffness_power.walk / 1000,
        trot: +this.reports.by_allures_sum_stiffness_power.trot / 1000,
        gallop: +this.reports.by_allures_sum_stiffness_power.gallop / 1000,
        sum: +this.reports.total_sum_stiffness_power / 1000,
      };

      return [
        {
          title: "Walk",
          percent: this.calculatePercent(powerData.walk, powerData.sum),
          data: Math.round(powerData.walk),
        },
        {
          title: "Trot",
          percent: this.calculatePercent(powerData.trot, powerData.sum),
          data: Math.round(powerData.trot),
        },
        {
          title: "Gallop",
          percent: this.calculatePercent(powerData.gallop, powerData.sum),
          data: Math.round(powerData.gallop),
        },
      ];
    },
    allEnergyCost() {
      return this.reports.total_energy_expended
        ? (this.reports.total_energy_expended / 1000).toFixed(2)
        : 0;
    },
    distanceByGait() {
      if (
        this.GPSDatafiles &&
        this.reports.by_allures_training_distance_GPS?.walk
      ) {
        if (this.trackingMode === trackingModeKeys.GPS) {
          return this.generateGPSDistanceByGait();
        } else if (this.trackingMode === trackingModeKeys.ML) {
          return this.generateMLDistanceByGait();
        }
      } else if (this.reports.by_allures_training_distance_ML?.walk) {
        return this.generateMLDistanceByGait();
      }
      return 0;
    },
    totalDistance() {
      if (this.GPSDatafiles && this.reports.total_distance_gps_meters) {
        if (this.trackingMode === trackingModeKeys.GPS) {
          return Math.ceil(this.reports.total_distance_gps_meters);
        } else if (this.trackingMode === trackingModeKeys.ML) {
          return Math.ceil(this.reports.total_distance_ml_meters);
        }
      } else if (this.reports.TRACKING && this.reports.TRACKING.ts) {
        return this.reports.total_distance_ml_meters;
      }
      return "-";
    },
    energyCoast() {
      const { by_allures_energy_expended } = this.reports || {};
      if (!by_allures_energy_expended) return null;
      const energies = this.allures.map((allure) => {
        const value = (by_allures_energy_expended[allure] / 1000).toFixed(2);
        return {
          title: allure.charAt(0).toUpperCase() + allure.slice(1),
          energy: +value,
        };
      });

      const totalEnergy = (this.reports.total_energy_expended / 1000).toFixed(
        2
      );
      return energies.map(({ title, energy }) => ({
        title: title,
        percent: this.calculatePercent(energy, totalEnergy),
        data: energy,
      }));
    },

    timeCoastByWalkingType() {
      if (!this.reports.by_allures_steps_count) return null;
      const trainingDurationKey = `by_allures_training_duration_${this.trackingMode}`;
      const totalDurationKey = `total_training_duration_${this.trackingMode}`;
      const allures = [...this.allures, "stay"];
      return allures.map((allure) => {
        const duration = this.reports[trainingDurationKey][allure];
        const totalDuration = this.reports[totalDurationKey];

        return {
          title: this.capitalizeFirstLetter(allure),
          percent: this.calculatePercent(duration, totalDuration),
          data: this.convertSecondsToTs(duration),
        };
      });
    },
    rhythmDeviationByAllure() {
      if (!this.reports.total_average_rhythm_deviation) return "-";

      const sum = Object.values(
        this.reports.by_allures_average_rhythm_deviation
      ).reduce((a, b) => a + b, 0);

      return [
        {
          title: "Walk",
          percent: this.calculatePercent(
            +this.reports.by_allures_average_rhythm_deviation.walk,
            sum
          ),
          data: this.reports.by_allures_average_rhythm_deviation.walk.toFixed(
            2
          ),
        },
        {
          title: "Trot",
          percent: this.calculatePercent(
            +this.reports.by_allures_average_rhythm_deviation.trot,
            sum
          ),
          data: this.reports.by_allures_average_rhythm_deviation.trot.toFixed(
            2
          ),
        },
        {
          title: "Gallop",
          percent: this.calculatePercent(
            this.reports.by_allures_average_rhythm_deviation.gallop,
            sum
          ),
          data: this.reports.by_allures_average_rhythm_deviation.gallop.toFixed(
            2
          ),
        },
      ];
    },
    rhythmDeviation() {
      if (!this.reports.total_average_rhythm_deviation) return "-";
      return this.reports.total_average_rhythm_deviation.toFixed(2);
    },
  },
  methods: {
    ...mapMutations([
      "SET_REPORTS_DATA",
      "SET_GPS_DATAFILES",
      "SET_HR_DATAFILES",
      "SET_HORSE_LAMENESS",
      "SET_CURRENT_DAY_TRAININGS_METADATA",
    ]),
    ...mapActions(["handleTrackingMode"]),
    convertSecondsToTs,

    generateGPSDistanceByGait() {
      const walk = Math.round(
        this.reports.by_allures_training_distance_GPS.walk
      );
      const trot = Math.round(
        this.reports.by_allures_training_distance_GPS.trot
      );
      const gallop = Math.round(
        this.reports.by_allures_training_distance_GPS.gallop
      );
      return [
        {
          title: "Walk",
          percent: this.calculatePercent(walk, this.totalDistance),
          data: walk,
        },
        {
          title: "Trot",
          percent: this.calculatePercent(trot, this.totalDistance),
          data: trot,
        },
        {
          title: "Gallop",
          percent: this.calculatePercent(gallop, this.totalDistance),
          data: gallop,
        },
      ];
    },
    generateMLDistanceByGait() {
      const walk = Math.round(
        this.reports.by_allures_training_distance_ML.walk
      );
      const trot = Math.round(
        this.reports.by_allures_training_distance_ML.trot
      );
      const gallop = Math.round(
        this.reports.by_allures_training_distance_ML.gallop
      );
      return [
        {
          title: "Walk",
          percent: this.calculatePercent(walk, this.totalDistance),
          data: walk,
        },
        {
          title: "Trot",
          percent: this.calculatePercent(trot, this.totalDistance),
          data: trot,
        },
        {
          title: "Gallop",
          percent: this.calculatePercent(gallop, this.totalDistance),
          data: gallop,
        },
      ];
    },
    calculatePercent(value, total) {
      return Math.round((value / total) * 100) || 0;
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>
