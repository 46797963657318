<template>
  <div class="w-full">
    <Line
      :data="chartData"
      :options="options"
      :plugins="plugins"
      ref="multiChart"
      id="chart"
      class="w-full min-h-[400px]"
    />
  </div>
</template>

<script>
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import { Line } from "vue-chartjs";
import annotationPlugin from "chartjs-plugin-annotation";
import { CrosshairPlugin } from "chartjs-plugin-crosshair";
import zoomPlugin from "chartjs-plugin-zoom";
import { mapGetters, mapMutations } from "vuex";
import { debounce } from "lodash";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  annotationPlugin,
  zoomPlugin,
  CrosshairPlugin
);

import {
  extractValueInBrackets,
  generateZeroLine,
  getTrackIndexFromTimestamp,
  setScalesMinMax,
} from "@/components/training/tracking/trackingComponents/multiChart/multiChartUtils";

export default {
  name: "MultichartComponent",
  components: { Line },
  emits: ["updateDecimationData", "setLocalZoom"],
  props: {
    chartData: {
      labels: Array,
      datasets: Array,
    },
    secondsByGait: {
      type: Array,
      required: true,
    },
    getMultichartColor: {
      type: Function,
      required: true,
    },
    visibleChartWasUpdatedFlag: Boolean,
  },
  inject: ["cutValue"],

  watch: {
    currentTrackIndex() {
      if (!this.isAnimationModalOpen) {
        this.drawDynamicAnnotation();
      }
    },

    localZoom: {
      handler: async function (newVal) {
        await this.$nextTick();
        const chart = this.$refs.multiChart.chart;
        setScalesMinMax(chart, newVal);

        chart.update("none");
      },
      deep: true,
    },

    async visibleChartWasUpdatedFlag() {
      await this.$nextTick();
      const chart = this.$refs.multiChart.chart;
      setScalesMinMax(chart, this.localZoom);

      this.handleZoomAndPanComplete(
        chart,
        this.localZoom.x.min,
        this.localZoom.x.max
      );
      this.setLocalZoom(chart, this.localZoom);

      await this.$nextTick();
    },
  },

  computed: {
    ...mapGetters([
      "reports",
      "currentTrackIndex",
      "isAnimationModalOpen",
      "localZoom",
    ]),

    plugins() {
      return [
        {
          id: "customBackground",
          beforeDraw: (chart) => {
            const {
              ctx,
              chartArea: { top, height, left, right },
              scales: { x },
            } = chart;

            ctx.save();

            if (!x.ticks.length || !this.secondsByGait) return;
            const intervals = this.secondsByGait;

            // Limiting position within the chart
            const clampPosition = (value) =>
              Math.max(Math.min(value, right), left);

            // Main intervals
            intervals.forEach((interval) => {
              if (!interval.isLine) {
                const startX = x.getPixelForValue(interval.start);
                const endX = x.getPixelForValue(interval.end);

                const clampedStart = clampPosition(startX);
                const clampedEnd = clampPosition(endX);

                if (clampedStart < clampedEnd) {
                  ctx.fillStyle = interval.color;
                  ctx.fillRect(
                    clampedStart,
                    top,
                    clampedEnd - clampedStart,
                    height
                  );
                }
              }
            });

            // Jumps intervals
            intervals.forEach((interval) => {
              if (interval.isLine) {
                const startX = x.getPixelForValue(interval.start);
                const clampedStart = clampPosition(startX);
                ctx.strokeStyle = interval.color;
                ctx.lineWidth = 2;
                ctx.beginPath();
                ctx.moveTo(clampedStart, top);
                ctx.lineTo(clampedStart, top + height);
                ctx.stroke();
              }
            });

            ctx.restore();
          },
        },

        {
          id: "customAnnotation",
          afterDraw: (chart) => {
            const {
              ctx,
              chartArea: { top, height },
              scales: { x },
            } = chart;

            if (this.currentTrackIndex) {
              const xPos = x.getPixelForValue(this.currentTrackIndex);

              ctx.save();
              ctx.strokeStyle = "red";
              ctx.lineWidth = 2;
              ctx.beginPath();
              ctx.moveTo(xPos, top);
              ctx.lineTo(xPos, top + height);
              ctx.stroke();
              ctx.restore();
            }
          },
        },
      ];
    },

    options() {
      const yAxes = this.chartData?.datasets?.reduce((acc, dataset, index) => {
        const axisId = dataset.yAxisID || `y${index}`;
        acc[axisId] = this.generateAxisOptions(axisId, dataset.label);
        return acc;
      }, {});

      const zeroLineAnnotations = Object.keys(yAxes).reduce(
        (annotations, axisId) => {
          annotations[`zeroLine_${axisId}`] = this.generateZeroLine(axisId);
          return annotations;
        },
        {}
      );

      return {
        animation: false,
        onClick: (event, item, chart) => {
          const xScale = chart.scales.x;
          const xValue = xScale.getValueForPixel(event.x);
          const label = chart.data.labels[Math.round(xValue)];
          const trackIndex = this.cutValue.value
            ? getTrackIndexFromTimestamp(label) - this.cutValue.value[0]
            : getTrackIndexFromTimestamp(label);

          this.SET_CURRENT_TRACK_INDEX(trackIndex);
        },
        plugins: {
          crosshair: {
            sync: {
              enabled: false,
            },
            zoom: {
              enabled: false,
            },
            line: {
              color: "gray",
              dashPattern: [5, 5],
            },
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "xy",
              overScaleMode: "y",
              onPanComplete: debounce(({ chart }) => {
                this.setLocalZoom(chart);
                this.handleZoomAndPanComplete(chart);
              }, 100),
            },
            zoom: {
              wheel: { enabled: true, modifierKey: "ctrl" },
              pinch: { enabled: true },
              mode: "xy",
              overScaleMode: "y",
              onZoomComplete: debounce(({ chart }) => {
                this.setLocalZoom(chart);
                this.handleZoomAndPanComplete(chart);
              }, 100),
            },
          },
          datalabels: false,
          decimation: true,
          colors: { forceOverride: true },
          annotation: {
            annotations: {
              annotation: {
                type: "line",
                borderWidth: 2,
                scaleID: "x",
                value: 0,
                adjustScaleRange: false,
              },
              ...zeroLineAnnotations,
            },
          },
          tooltip: {
            intersect: false,
            enabled: false,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        height: 400,
        interaction: {
          mode: "nearest",
          axis: "x",
          intersect: false,
          display: false,
        },
        scales: {
          x: {
            type: "category",
            ticks: {
              callback: function (value) {
                const currentValue = this.getLabelForValue(value);
                const [hours, minutes, seconds] = currentValue.split(":");
                const totalMinutes =
                  parseInt(hours, 10) * 60 + parseInt(minutes, 10);
                return `${totalMinutes}:${seconds}`;
              },
              maxRotation: 30,
              autoSkip: true,
              color: (context) => {
                const timeValue = context.tick.value;

                const currentGait =
                  this.secondsByGait?.find((i) => i.second === timeValue) ||
                  this.defaultGaitData;

                switch (currentGait?.gait) {
                  case "walk":
                    return "#1AB0B0";
                  case "trot":
                    return "#FFCD4B";
                  case "gallop":
                    return "#F85C7F";
                  default:
                    return "gray";
                }
              },
            },
          },
          ...yAxes,
        },
      };
    },
  },

  methods: {
    ...mapMutations(["SET_CURRENT_TRACK_INDEX"]),
    generateZeroLine,

    handleZoomAndPanComplete(chart, newMin, newMax) {
      this.$emit("updateDecimationData", chart, newMin, newMax);
    },

    setLocalZoom(chart) {
      this.$emit("setLocalZoom", chart);
    },

    generateAxisOptions(id, label) {
      return {
        id: id,
        type: "linear",
        position: "left",
        ticks: {
          color: this.getMultichartColor(id),
          padding: 15,
        },
        border: {
          color: this.getMultichartColor(id),
        },
        grid: {
          color: this.getMultichartColor(id),
          drawOnChartArea: false,
        },
        title: {
          display: false,
          text: extractValueInBrackets(this.$t(label)),
          color: this.getMultichartColor(id),
          font: {
            weight: "bold",
          },
          padding: { top: 20, left: 0, right: 0, bottom: 0 },
        },
      };
    },

    async drawDynamicAnnotation() {
      const chart = this.$refs.multiChart.chart;
      if (!chart) return;

      const currentSecond =
        this.currentTrackIndex > 0 ? this.currentTrackIndex : 0;
      const totalRange = chart.data.labels.length - 1;
      const currentMin = chart.options.scales.x.min ?? 0;
      const currentMax = chart.options.scales.x.max ?? totalRange;

      chart.options.plugins.annotation.annotations.annotation.value =
        currentSecond;

      // Keep current xRange if current seconds is in visible range
      if (currentSecond >= currentMin && currentSecond <= currentMax) {
        chart.update("none");
        return;
      }

      // Set current second in the center of xRange
      const step = currentMax - currentMin;
      let newMin = currentSecond - Math.floor(step / 2);
      let newMax = currentSecond + Math.floor(step / 2);

      if (newMin < 0) {
        newMin = 0;
        newMax = step;
      } else if (newMax > totalRange) {
        newMax = totalRange;
        newMin = totalRange - step;
      }

      chart.options.scales.x.min = newMin;
      chart.options.scales.x.max = newMax;
      chart.update("none");

      this.handleZoomAndPanComplete(chart, newMin, newMax);
    },

    handleKeyDown(event) {
      const { key } = event;
      if (key === "Alt") {
        const chart = this.$refs.multiChart.chart;
        chart.options.plugins.tooltip.enabled = true;
        chart.update();
      }
    },
    handleKeyUp(event) {
      const { key } = event;
      if (key === "Alt") {
        const chart = this.$refs.multiChart.chart;
        chart.options.plugins.tooltip.enabled = false;
        chart.update();
      }
    },
  },

  async mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
  },

  beforeUnmount() {
    if (this.$refs.multiChart) {
      document.removeEventListener("keydown", this.handleKeyDown);
      document.removeEventListener("keyup", this.handleKeyUp);
    }
  },
};
</script>

<style lang="scss" scoped></style>
